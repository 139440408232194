import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationPt from "./locales/ptBR/translation.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: "pt_BR",
    fallbackLng: "pt_BR",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      pt_BR: {
        translations: translationPt,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;