import { StyledButton } from "./styles";
import { ButtonProps } from "../types";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#ffffff" }} spin />
);

export const Button = ({
  color,
  background,
  fixedWidth,
  children,
  onClick,
  loading,
  ...rest
}: ButtonProps) => (
  <StyledButton
    color={color}
    background={background}
    fixedWidth={fixedWidth}
    onClick={onClick}
    disabled={loading}
    {...rest}
  >
    {loading ? <Spin indicator={antIcon} /> : children}
  </StyledButton>
);
