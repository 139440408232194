import { SvgIconProps } from "../types";

export const SvgIcon = ({
  src,
  width,
  height,
  animation = false,
}: SvgIconProps) =>
  animation ? (
    <object
      aria-label={src}
      type="image/svg+xml"
      data={`/img/svg/${src}`}
      width={width}
      height={height}
    />
  ) : (
    <img src={`/img/svg/${src}`} alt={src} width={width} height={height} />
  );
