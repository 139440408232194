import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Extra,
  LogoContainer,
  Para,
  Empty,
  Language,
} from "./styles";

const Footer = ({ t }: any) => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={24} xs={24}>
              <Empty />
              <Language>Endereço</Language>
              <Para>Rua Francisco Prota, S/N</Para>
              <Para>Centro, Picos-PI</Para>
              <Para>CEP: 64600-026</Para>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Empty />
              <Language>Contato</Language>
              <Para>
                <SvgIcon
                  src="whatsapp.png"
                  width={"15px"}
                  height={"15px"}
                  animation={false}
                />
                Telefone: 89 3199 0990
              </Para>
              <Para>
                <SvgIcon
                  src="mail.png"
                  width={"15px"}
                  height={"15px"}
                  animation={false}
                />
                Email: comercial@ispcredi.com.br
              </Para>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Empty />
              <Language>Mais informações</Language>
              <Para>Virtex Facilitadora de Credito LTDA</Para>
              <Para>CNPJ: 50.326.110/0001-16. </Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <p>
            A ISPCREDI pertence e é operada pela VIRTEX FACILITADORA DE CREDITO
            LTDA, registrada sob o CNPJ/MF 50.326.110/0001-16, localizada na rua
            Francisco Prota, S/N - Centro - Picos - PI – 64.600-026. Somos um
            correspondente bancário parceiro da MOVA SOCIEDADE DE EMPRÉSTIMO
            ENTRE PESSOAS S.A, registrada sob o CNPJ/MF 33.959.738/0001-30 e
            possuímos uma plataforma online com produtos e serviços ofertados
            por instituições financeiras parceiras. Nosso prazo de pagamento
            varia de 3 a 36 meses, a taxa de juros praticada no produto de
            crédito pessoal é de 1,99% a.m. até 7,00% a.m. e o. A tarifa de
            cadastro (TAC) é de R$ 30,00 até R$ 300,00. Ao solicitar uma
            proposta, serão exibidos a taxa de juros utilizada, a tarifa, o
            imposto (IOF) e o custo efetivo total (CET). Exemplo: um empréstimo
            de R$ 1.000,00 em 12 meses com taxa de juros de 7,00% a.m. terá
            parcelas de R$ 135.91 e CET de (165,10% a.a.).
          </p>
        </Container>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <Col lg={12} md={12} sm={24} xs={24}>
              <LogoContainer>
                <SvgIcon
                  src="virtexcredi.png"
                  aria-label="homepage"
                  width="240px"
                  height="auto"
                  animation={false}
                />

                <a
                  target="_blank"
                  href="https://www.virtex.com.br/"
                  rel="noreferrer"
                  style={{ alignSelf: "center" }}
                >
                  uma empresa <strong>VirteX</strong>
                </a>
              </LogoContainer>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <span>
                Copyright 2023 © VirteX. Todos os direitos reservados.
              </span>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
